@import "../bootstrap/functions";
@import "../bootstrap/variables";

body.login {
  background-color: black;
  
  #login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 600px;  
  }
  
  #wp-submit {
      background-color: black;
      border: 1px solid darken(black, 5%) !important;
      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      transition: 250ms ease all;

    &:hover {
      background-color: darken(black, 10%) !important;
    }
  }

}
